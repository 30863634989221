import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "submitButton"];

  connect() {
    this.disableOrEnable();
    this.checkboxTarget.addEventListener(
      "change",
      this.disableOrEnable.bind(this),
    );
  }

  disconnect() {
    this.checkboxTarget.removeEventListener("change", this.disableOrEnable);
  }

  disableOrEnable() {
    this.submitButtonTarget.disabled = !this.checkboxTarget.checked;
  }
}
