// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as Sentry from "@sentry/browser";

if (window.GH?.SENTRY_DSN) {
  Sentry.init({
    dsn: window.GH.SENTRY_DSN,
    environment: window.GH.SENTRY_ENV,

    release: process.env.SOURCE_VERSION,

    tracesSampleRate: 0.25,

    denyUrls: [
      // Chrome extensions (https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry)
      /extensions\//i,
      /^chrome:\/\//i,
      // Zendesk
      /static\.zdassets\.com\//i,
    ],

    replaysOnErrorSampleRate: 1.0,

    integrations: [
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
  });

  Sentry.setUser({
    username: window.GH.SENTRY_USER_NAME || null,
    id: window.GH.SENTRY_USER_ID || null,
    email: window.GH.SENTRY_USER_EMAIL || null,
    masquerading_superuser_id: window.GH.SENTRY_SUPERUSER_ID || null,
    masquerading_superuser_email: window.GH.SENTRY_SUPERUSER_EMAIL || null,
  });
}

window.triggerSentryError = function () {
  throw new Error("Test Sentry Error");
};

// Importing Rails and Turbo after Sentry to ensure that Sentry can properly
// instrument its fetch and XHR callbacks first.
import * as ActiveStorage from "@rails/activestorage";
import LocalTime from "local-time";

import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import "@hotwired/turbo-rails";

ActiveStorage.start();
LocalTime.start();

import "bootstrap";

import "@fortawesome/fontawesome-free/css/all";

import "../controllers";

import "../popovers";
import "../tooltips";
